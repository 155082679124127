import React from "react";

import CycleImage from "./cycle-image";
import Layout from "./layout";

import pepeImg from "../images/pepe.webp";

const HomepageLayout = (props) => {
  return (
    <div className="homepage overflow-x-hidden">
      <div className="homepage-banner flex items-start sm:p-30 lg:p-30 shrink-0 relative bg-red-500">
        <CycleImage
          src={pepeImg}
          className="absolute inset-0 object-cover bg-red-900 w-full h-full crisp"
        />
        <p className="homepage-intro relative w-full pb-16 self-center font-sans text-3xl font-bold condensed uppercase text-gray-200 text-center whitespace-pre-line xl:px-8 sm:text-5xl xl:text-8xl xxl:text-9xl">
          {props.data.site.homepageIntro}
          {/* <a
            href="#top"
            className="absolute z-10 right-0 bottom-0 flex justify-center items-center h-16 w-16 xl:h-18 xl:w-18 bg-red-500 text-grey-100 text-xl xl:text-3xl tracking-none"
          >
            ↴
          </a> */}
        </p>
        <div className="corner-scoop absolute bottom-0 corner-scoop-l">
          <svg
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-gray-50 dark:fill-gray-900"
          >
            <path d="M10,0Q10,10,0,10H10Z" />
          </svg>
        </div>
        <div className="corner-scoop absolute bottom-0 corner-scoop-r">
          <svg
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-gray-50 dark:fill-gray-900"
          >
            <path d="M0,0Q0,10,10,10H0Z" />
          </svg>
        </div>
      </div>
      <div id="top" className="absolute" />
      <Layout {...props} />
    </div>
  );
};

export default HomepageLayout;
