import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "cnz";

import IMAGE_VERT from "raw-loader!./image.vert";
import CYCLE_FRAG from "raw-loader!./cycle.frag";

function smudge(src, dest, angle = 0.005, iterations = 8) {
  const { width, height } = src;
  const ctx = dest.getContext("2d");
  for (let i = 0; i < iterations; ++i) {
    ctx.save();
    ctx.translate(width / 2, height / 2);
    ctx.rotate((i - Math.floor(iterations / 2)) * angle);
    ctx.translate(-width / 2, -height / 2);
    ctx.drawImage(src, 0, 0);
    ctx.restore();
  }
}

class CycleImageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      "An error occurred while trying to run the CycleImage shader.",
      error,
      errorInfo
    );
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI.
      return (
        <div className={cn(this.props.className)}>
          <img
            src={this.props.src}
            alt={this.props.alt ?? ""}
            className={cn("cycle-image-fallback", this.props.className)}
          />
        </div>
      );
    }
    return this.props.children;
  }
}

function CycleImage({ src, ...otherProps }) {
  const canvasRef = useRef(null);
  const timeoutRef = useRef(null);
  const glRef = useRef(null);
  const glTimestampRef = useRef(null);
  const timestampOffset = useRef(Math.random() * 10000);
  const [image, setImage] = useState(null);

  const tick = useCallback(function tick(t) {
    const gl = glRef.current;
    const canvas = canvasRef.current;
    if (gl && canvas) {
      const ctx = canvas.getContext("2d");
      gl.uniform1f(glTimestampRef.current, t + timestampOffset.current);
      gl.drawArrays(gl.TRIANGLES, 0, 6);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      smudge(gl.canvas, canvas, Math.PI / 600, 3);
      // or,
      // ctx.drawImage(gl.canvas, 0, 0);
    }
    timeoutRef.current = requestAnimationFrame(tick);
  }, []);

  const setup = useCallback(
    function setup(image) {
      // TODO: Simplify this to one canvas by porting `smudge` to the shader.
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      // TODO: This looks better, but… you’ll see weird boxes if you turn it on.
      // As far as I can tell, this is because WebGL contexts don’t have an
      // `imageSmoothingEnabled` attribute, and something about copying from one
      // context (aliased) to the other (pixelated) causes some weird deadzones.
      // I’m pretty certain that’s the source of the weird boxes, so porting
      // `smudge` to the shader might get around it.
      // ctx.imageSmoothingEnabled = false;
      ctx.globalCompositeOperation = "darken";

      const glCanvas = document.createElement("canvas");
      glCanvas.width = image.naturalWidth;
      glCanvas.height = image.naturalHeight;

      const gl = glCanvas.getContext("webgl");
      gl.imageSmoothingEnabled = false;
      glRef.current = gl;

      gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

      // Create the vertex shader.
      const vertexShader = gl.createShader(gl.VERTEX_SHADER);
      gl.shaderSource(vertexShader, IMAGE_VERT);
      gl.compileShader(vertexShader);

      // Create the fragment shader.
      const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
      gl.shaderSource(fragmentShader, CYCLE_FRAG);
      gl.compileShader(fragmentShader);

      // Create the GL program.
      const program = gl.createProgram();
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);

      // Enable the program.
      gl.useProgram(program);

      // Bind VERTICES as the active array buffer.
      const VERTICES = new Float32Array([
        -1, -1, -1, 1, 1, 1, -1, -1, 1, 1, 1, -1,
      ]);

      const vertexBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, VERTICES, gl.STATIC_DRAW);

      // Set and enable our array buffer as the program's "position" variable.
      const positionLocation = gl.getAttribLocation(program, "position");
      gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(positionLocation);

      // Pass a timestamp to each rendered frame.
      glTimestampRef.current = gl.getUniformLocation(program, "timestamp");

      // Create a texture.
      const texture = gl.createTexture();
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.LUMINANCE,
        gl.LUMINANCE,
        gl.UNSIGNED_BYTE,
        image
      );
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

      timeoutRef.current = requestAnimationFrame(tick);
    },
    [tick]
  );

  useEffect(() => {
    if (image) {
      setup(image);
    }
  }, [image, setup]);

  useEffect(() => {
    const image = new Image();
    image.onload = function () {
      setImage(this);
    };
    image.src = src;
    return () => {
      image.onload = null;
      window.cancelAnimationFrame(timeoutRef.current);
    };
  }, [src, setup]);

  return <canvas ref={canvasRef} {...otherProps} />;
}

function SafeCycleImage(props) {
  return (
    <CycleImageErrorBoundary {...props}>
      <CycleImage {...props} />
    </CycleImageErrorBoundary>
  );
}

export default SafeCycleImage;
