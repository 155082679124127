import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useCallback, useEffect } from "react";
import cn from "cnz";
import { isFuture } from "date-fns";

import Logo from "../images/inline/OHREP.svg";

// Get the oldest published post.
const headerQuery = graphql`
  query HeaderQuery {
    posts: allSanityPost(
      limit: 1
      sort: { fields: [publishedAt], order: ASC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
        }
      }
    }
  }
`;

const MenuItem = ({ to, children, isActive, onClose }) => (
  <li className="flex grow">
    <Link
      to={to}
      className={cn(
        "m-auto uppercase md:normal-case font-bold semi-condensed md:font-medium text-6xl md:text-sm hover:text-red-700",
        isActive
          ? "text-gray-400 md:hover:text-gray-400"
          : "text-gray-100 md:text-gray-900 dark:md:text-gray-100 md:hover:text-red-900 dark:md:hover:text-red-100"
      )}
      onClick={isActive ? onClose : () => {}}
    >
      {children}
    </Link>
  </li>
);

const Header = ({ isNavOpen, setIsNavOpen, siteTitle, location }) => {
  const earliestPostPublishedAt =
    useStaticQuery(headerQuery).posts.edges[0]?.node?.publishedAt;
  const shouldShowBlogLink =
    earliestPostPublishedAt && !isFuture(new Date(earliestPostPublishedAt));

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isNavOpen]);

  const handleClose = useCallback(() => {
    setIsNavOpen(false);
  }, [setIsNavOpen]);

  useEffect(handleClose, [location.pathname, handleClose]);

  return (
    <nav className="py-2 sm:py-4">
      <div className="flex flex-wrap justify-between items-baseline">
        <Link to="/" className="flex items-baseline">
          <Logo className="h-9 mr-2 dark:invert" />
          <span className="pr-2 font-sans semi-condensed text-2xl leading-none font-bold whitespace-nowrap dark:text-white relative">
            {siteTitle}
          </span>
        </Link>
        <button
          data-collapse-toggle="nav-menu"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-gray-600 self-center"
          aria-controls="nav-menu"
          aria-expanded={isNavOpen}
          onClick={() => setIsNavOpen((x) => !x)}
          aria-label="Open main menu"
        >
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <svg
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={cn(
            !isNavOpen && "hidden",
            "fixed md:static z-20 inset-0 w-full flex flex-col md:block md:w-auto bg-gray-900 md:bg-transparent"
          )}
          id="nav-menu"
        >
          <div className="md:hidden flex gap-x-4 px-4 justify-between items-center h-16">
            <Link
              to="/"
              className="flex items-baseline"
              onClick={() => location.pathname === "/" && handleClose()}
            >
              <Logo className="h-9 invert" />
            </Link>
            <button
              type="button"
              className="flex justify-center items-center w-9 h-9 text-4xl text-gray-200 x-button"
              onClick={handleClose}
              aria-label="Close navigation menu"
            />
          </div>
          <ul className="flex grow flex-col md:flex-row md:space-x-8 font-medium font-sans md:static">
            {shouldShowBlogLink && (
              <MenuItem
                to="/blog"
                isActive={location.pathname.startsWith("/blog")}
              >
                Blog
              </MenuItem>
            )}
            <MenuItem
              to={
                typeof window !== "undefined" &&
                window.navigator.language?.slice(0, 2) === "fr"
                  ? "/guide/fr"
                  : "/guide"
              }
              isActive={location.pathname.startsWith("/guide")}
              onClose={handleClose}
            >
              Guide
            </MenuItem>
            <MenuItem
              to="/research"
              isActive={location.pathname.startsWith("/research")}
              onClose={handleClose}
            >
              Research
            </MenuItem>
            <MenuItem
              to="/educate"
              isActive={location.pathname.startsWith("/educate")}
              onClose={handleClose}
            >
              Educate
            </MenuItem>
            <MenuItem
              to="/media"
              isActive={location.pathname.startsWith("/media")}
              onClose={handleClose}
            >
              Media
            </MenuItem>
            <MenuItem
              to="/friends"
              isActive={location.pathname.startsWith("/friends")}
              onClose={handleClose}
            >
              Friends
            </MenuItem>
            {/* <MenuItem
              to="/blog"
              isActive={location.pathname.startsWith("/blog")}
              onClose={handleClose}
            >
              Blog
            </MenuItem> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
