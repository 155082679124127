import React from "react";
import cn from "cnz";

const Container = ({ children, noPad, className }) => (
  <div
    className={cn(
      !noPad && "px-2 sm:px-4",
      "w-full mx-auto max-w-screen-xl",
      className
    )}
  >
    {children}
  </div>
);

export default Container;
