import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Pagination,
} from "react-instantsearch-hooks-web";
import { Link } from "gatsby";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APPLICATION_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_ONLY_KEY
);

/* NOTE: The HIT component standard link element instead of Gatsby Link because Search results aren't 
cleared properly with gatsby link after navigating to new records */
// function Hit({ hit }) {
//   const categories = new Map([
//     ["contextual", "contextuels"],
//     ["historical", "historiques"],
//     ["explicit", "explicites"],
//     ["characters", "personnages"],
//     ["contemporary", "contemporains"],
//   ]);
//   return (
//     <a href={`/guide/${hit.category}/${hit.slug}`}>
//       {/* <a href={isFrench ? `French` : `EN`}> */}
//       {/* <img src={hit.image} alt={hit.name} /> */}
//       {/* <p>{hit.categories[0]}</p> */}
//       <h1>
//         <Highlight attribute="title" hit={hit} />
//       </h1>

//       {/* <p>${hit.price}</p> */}
//     </a>
//   );
// }
/* Detecting empty search requests: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/#detecting-empty-search-requests */
const SearchUI = ({ isFrench = false }) => {
  const [showPagination, setShowPagination] = useState(false);
  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        setShowPagination(false);
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: "",
            params: "",
          })),
        });
      }
      setShowPagination(true);
      return algoliaClient.search(requests);
    },
  };

  const categories = new Map([
    ["contextual", "contextuels"],
    ["historical", "historiques"],
    ["explicit", "explicites"],
    ["characters", "personnages"],
    ["contemporary", "contemporains"],
  ]);
  return (
    <div className="my-5 md:mb-0 w-full lg:max-w-2xl">
      <InstantSearch
        searchClient={searchClient}
        indexName={isFrench ? "symbols_fr" : "symbols_en"}
      >
        <SearchBox
          placeholder={isFrench ? "Chercher dans le Guide" : "Search the Guide"}
          classNames={{
            root: "",
            input: "pl-10 placeholder-gray-500 text-black ",
            form: "",
          }}
        />
        {/* Hit component inline b/c of isFrench value */}
        <Hits
          hitComponent={({ hit }) => {
            return (
              <a
                href={
                  isFrench
                    ? `/guide/fr/${categories.get(hit.category)}/${hit.slug}`
                    : `/guide/${hit.category}/${hit.slug}`
                }
                className={"w-full group flex items-center gap-x-5"}
              >
                <img
                  src={`${hit.imageUrl}?h=100&w=100`}
                  alt={hit.alt}
                  className="rounded-full w-12 h-12"
                />
                <h1>
                  <Highlight
                    attribute="title"
                    hit={hit}
                    className="highlighted group-hover:underline"
                  />
                </h1>
              </a>
            );
          }}
        ></Hits>
        {showPagination && <Pagination />}
      </InstantSearch>
    </div>
  );
};

export default SearchUI;
