import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import "instantsearch.css/themes/satellite.css";

import Header from "./header";
import GraphQLErrorList from "./graphql-error-list";
import Container from "./container";
import SearchUI from "./search/SearchUI";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

const Layout = ({ children, errors, location }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const siteData = useStaticQuery(query);
  if (!siteData.site) {
    throw new Error(
      'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
    );
  }

  return (
    <>
      {errors && <GraphQLErrorList errors={errors} />}
      <div className="page-content flex flex-col min-h-screen max-w-full">
        <Container className="inner-content">
          <Header
            location={location}
            siteTitle={siteData.site.title}
            setIsNavOpen={setIsNavOpen}
            isNavOpen={isNavOpen}
          />
          {/* <SearchUI /> */}
          <main className="flex-grow">{children}</main>
        </Container>
        <Container className="mt-auto" noPad>
          <footer className="px-4 py-6 mt-12 sm:px-4 border-t border-gray-200 text-sm text-gray-500 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-200 transition-colors">
            <div>
              <p className="mb-3">
                Hatepedia was produced by the Online Hate Research and Education
                Project, which is an initiative of The Toronto Holocaust Museum.
                For more information, please{" "}
                <a
                  className="underline"
                  href="https://torontoholocaustmuseum.org/"
                >
                  visit our website
                </a>{" "}
                or contact us at info [at] thethm.org.
              </p>
              <div className="flex flex-col md:flex-row flex-wrap justify-between gap-3">
                <p>
                  Hatepedia and OHREP have been made possible in part by the
                  Government of Canada.
                </p>
                <p>
                  Hatepedia et OHREP a été rendu possible en partie grâce au
                  gouvernement du Canada.
                </p>
              </div>
            </div>
            {/* <div className="mt-6 sm:mt-3 sm:flex sm:items-center sm:justify-between">
            <span className="sm:text-center">
              &copy; {new Date().getFullYear()}{" "}
              <a href="https://hatepedia.ca" className="hover:underline">
                Hatepedia™
              </a>
              . All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center">
              <li>
                <a href="#" className="mr-4 hover:underline sm:mr-6">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="mr-4 hover:underline sm:mr-6">
                  Licensing
                </a>
              </li>
              <li>
                <a
                  href="mailto:neuberger@ujafed.org"
                  className="hover:underline"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
          </footer>
        </Container>
      </div>
    </>
  );
};

export default Layout;
