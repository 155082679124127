/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require("react");

const HomepageLayout = require("./src/components/homepage-layout").default;
const Layout = require("./src/components/layout").default;

require("./src/styles/global.css");

exports.wrapPageElement = ({ element, props }) => {
  const isHomepage =
    props.location.pathname === "/" && props.data?.site?.homepageIntro;
  return isHomepage ? (
    <HomepageLayout {...props}>{element}</HomepageLayout>
  ) : (
    <Layout {...props}>{element}</Layout>
  );
};
